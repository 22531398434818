@import "~antd/dist/antd.less";
@import "./variables.less";

.App {
  background: @warning-color;
}

.site-logo {
  height: 64px;
  line-height: 64px;
  text-align: center;
}

.link {
  color: @link-color;
  cursor: pointer;
}

.ant-layout {
  min-height: 100vh;
}

.align-center {
  text-align: center;
}

.top-margin {
  margin-top: 1rem;
}

.form-login-container {
  padding: 16px 32px;
  background-color: @greyish-white;
  border-radius: 4px;
}
// .form-prescription-container {
//   // width: 100%;
//   padding: 10px 0px;
//   // background-color: whitesmoke;
//   border-radius: 4px;
// }

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.site-layout .site-layout-background {
  background: white;
  position: relative;
}

.logo-container {
  margin: 10px auto auto;
  padding: 32px;
  border-radius: 50%;
  background-color: #414042;
  height: 90px;
  width: 90px;
}

.ant-layout-header {
  height: 64px;
  padding: 0 18px;
  background: rgb(170, 170, 170);
  line-height: 64px;
}

.site-header {
  display: flex;
  justify-content: space-between;
}

.call_icon {
  margin-left: 8px;
  font-size: 20px;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: white;
  transition: all 0.2s;
}

.sidebar-container {
  position: relative;

  .sidebar-trigger {
    background-color: @primary-color;
    color: @greyish-white;
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
  }
}

.synthetic-id-icon-container {
  color: inherit;
  font-size: 1rem;

  .synthetic-id-icon {
    transition: transform 200ms ease-in;
  }

  .synthetic-id-icon:hover {
    transform: scale(1.25) translate(2px, -2px);
  }
}

// override
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
}

.scrollable {
  height: calc(100vh - 160px);
  overflow-y: scroll;

  .ant-list-header {
    background-color: @greyish-white;
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.case-reminder {
  height: 300px;
}

.lead-details {
  .lead-info {
    border: 1px solid @greyish-white;
    border-radius: 2px;
    padding: 16px 24px;
  }

  .lead-details-tabs {
    border-top: 1px solid @light-grey;
    border-right: 1px solid @light-grey;
    border-radius: 2px;
    padding: 16px 24px;

    .geturl-container {
      position: sticky;
      top: 0;
      padding: 4px 1rem;
      z-index: 1;
      background-color: #f1f1f1;
    }
  }

  .lead-image {
    .ant-image-img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .recent-comment {
    height: 324px;
    overflow-y: scroll;
    width: 100%;

    .ant-list-header {
      background-color: #fcfcfc;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .delete-btn {
      width: 32px;
    }
  }

  .comment-section {
    .ant-row:nth-child(1) {
      flex: 10;
      padding: 16px 0;
      margin: 0 8px 0 0;
    }

    .ant-row:nth-child(2) {
      flex: auto;
      padding: 16px 0;
      margin: 0;
    }

    .ant-row:nth-child(3) {
      flex: auto;
      padding: 16px 0;
      margin: 0 0 0 8px;
    }
  }
}

.write-prescription {
  background-color: @primary-color;
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 99;
}
.order-data-card-body {
  width: 19rem;
  font-weight: bold;
  font-size: 40px;
}

.order_link_form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align-last: start;
}

.order-filter > .ant-form-item {
  margin-right: 0.25rem;
}
.ant-form-item-explain {
  font-size: 13px;
}
.image {
  .ant-image-img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.text-success {
  color: @success-color;
}
.text-success:hover,
.text-success:active,
.text-success:focus {
  color: hsl(100, 77%, 34%);
}
.text-danger {
  color: @error-color;
}
.text-danger:hover,
.text-danger:active,
.text-danger:focus {
  color: hsl(357, 91%, 45%);
}

.bg-transparent {
  background-color: transparent;
}
.bg-success {
  background-color: @success-color;
}
.bg-success-50 {
  background-color: hsl(100, 77%, 97%);
  border-color: hsl(100, 77%, 97%);
}
.bg-success-100 {
  background-color: hsl(100, 77%, 92%);
  border-color: hsl(100, 77%, 92%);
}
.bg-danger {
  background-color: @error-color;
}
.bg-danger-50 {
  background-color: hsl(357, 91%, 97%);
  border-color: hsl(357, 91%, 97%);
}

.block {
  display: block;
}

.grid {
  display: grid;
}
.admin__sales__dashboard-grid {
  grid-template-columns: repeat(5, minmax(15rem, 1fr));
}
.admin__post_sales__dashboard-grid {
  grid-template-columns: repeat(4, minmax(15rem, 1fr));
}
.ticket__attchments {
  grid-template-columns: repeat(3, minmax(96px, 1fr));
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-1 {
  flex: 1 1 0%;
}

.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}

.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-auto {
  overflow: auto;
}

.h-3 {
  height: 0.75rem;
}
.h-full {
  height: 100%;
}
.h-max {
  height: max-content;
}

.w-3 {
  width: 0.75rem;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.w-max {
  width: max-content;
}
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.px-8 {
  padding-right: 1rem;
  padding-left: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.m-4 {
  margin: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-16 {
  margin-left: 4rem;
}
.mt-px {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-16 {
  margin-top: 4rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-8 {
  margin-bottom: 2rem;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}

.justify-items-end {
  justify-items: end;
}
.justify-items-center {
  justify-items: center;
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.top-0 {
  top: 0;
}
.fw-400 {
  font-weight: 400;
}
.br-1 {
  border-radius: 4px;
}
.zIndex-top {
  z-index: 1;
}
// custome code
.backBtn {
  font-family: "Rubik", sans-serif;
}

.cardView {
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 10px 15px;
}

.activeTag {
  font-family: "Rubik", sans-serif;
  padding: 7px 12px;
  background: @primary-color;
  color: @greyish-white;
  border-radius: 4px;
  cursor: pointer;
}

.inactiveTag {
  font-family: "Rubik", sans-serif;
  padding: 7px 12px;
  cursor: pointer;
  color: #000;
}

.sticky {
  position: sticky;
}

// ant-design
.slick-slide div {
  width: 250px;
}
.ant-image-mask-info {
  text-align-last: center !important;
}
.dltImgIcon {
  display: none;
  position: absolute;
  z-index: 99999999;
  top: -10px;
  right: -10px;
  padding: 8px 12px;
  background: #fff;
  border-radius: 100px;
  width: 40px !important;
  height: 40px;
}
.carouselCon:hover .dltImgIcon {
  display: block !important;
}
.anticon-phone {
  border: 1px solid;
  padding: 7px;
  border-radius: 30px;
}
.ant-input-prefix {
  margin-right: 15px;
  margin-left: 10px;
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  background-color: #f9f8f8;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  padding-left: 15px;
}
.ant-input-affix-wrapper {
  padding: 0px 0px;
}

.media-scroller-3 {
  /* --_spacer: var(--size-5); */
  display: grid;
  /* gap: var(--size-3); */
  grid-auto-flow: column;
  grid-auto-columns: 90%;
  padding: 0 var(--_spacer) var(--_spacer);
  overflow-x: auto;
  overscroll-behavior-inline: contain;
}
.media-element {
  display: grid;
  grid-template-rows: min-content;
  gap: var(--_spacer);
  padding: var(--_spacer);
  background: var(--surface-2);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
}
.snaps-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--_spacer, 1rem);
}
.snaps-inline > * {
  scroll-snap-align: start;
}
.video-box {
  width: 300px;
  height: 180px;
}
@media (min-width: 1280px) {
  .video-box {
    width: 280px;
    height: 160px;
  }
  .media-scroller-3 {
    /* --_spacer: var(--size-5); */
    display: grid;
    /* gap: var(--size-3); */
    grid-auto-flow: column;
    grid-auto-columns: 95%;
    padding: 0 var(--_spacer) var(--_spacer);
    overflow-x: auto;
    overscroll-behavior-inline: contain;
  }
  .ant-input-prefix {
    margin-right: 15px;
    margin-left: 10px;
  }
  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    background-color: #f9f8f8;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    padding-left: 15px;
  }
  .ant-input-affix-wrapper {
    padding: 0px 0px;
  }
  .cta {
    width: 350px;
  }
  .content-box {
    width: 350px;
  }
  .download-btn {
    width: 350px;
  }
  .medicineContainer {
    width: 350px;
  }
  #prescriptionBody {
    // margin-left: 535px;
    // display: flex;
    // justify-content: center;
  }
  .prescriptionBox {
    width: 350px;
    margin-left: 40%;
  }
  .dashboardBar {
    width: 343px;
    height: 0px;
    margin-top: 10px;
    border: 1.5px solid #b7d340;
    margin-bottom: 25px;
  }
  .topText {
    width: 343px;
    height: 40px;
    font-family: Fredoka;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    text-align: left;
    color: #414042;
  }
  .faq {
    width: 343px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #bababa;
  }
  .headerText {
    width: 343px;
    // height: 90px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    text-align: left;
    color: #414042;
  }
  .footer {
    width: 343px;
    padding: 1% 0%;
  }
  .form-box {
    width: 343px;
    margin: 0 auto;
  }
  .subHeaderText {
    width: 100%;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: left;
    color: #414042;
    margin-top: 10px;
  }
  .inputwidth {
    width: 100%;
    height: 54px;
    font-family: "Nunito Sans";
    border-radius: 32px;
    border-color: #b7d340;
    border-width: 2px;
    text-align: left;
    color: #939393;
    background-color: #f9f8f8;
    font-size: 18px;
    font-weight: 600;
  }
  .findOrderIdContainer {
    width: 100%;
  }
  .form-prescription-container {
    width: 343px;
    padding: 10px 0px;
    // background-color: whitesmoke;
    border-radius: 4px;
  }
  .form-find-orderId-container {
    width: 25%;
    padding: 16px 32px;
    // background-color: whitesmoke;
    border-radius: 4px;
  }
  .findOrderIdHeader {
    width: 25%;
  }
  .container {
    position: relative;
    width: 350px;
    height: auto;
    // margin: 0 auto;
  }
  .headerBar {
    width: 350px;
    height: 0px;
    margin-top: 10px;
    border: 0.2px solid #ebf1e2;
  }
  .userDetailsContainer {
    width: 350px;
    height: 60px;
    margin-top: 5px;
    padding: 20px;
    // display: flex;
    // justify-content: space-between;
  }
  .userDetail {
    width: 100%;
    height: auto;
    margin-top: -8px;
    margin-left: -15px;
  }
  .userDiagnosisCard {
    width: 450px;
    height: 135px;
    margin-top: 10px;
    background-color: #f8f8f8;
    padding: 20px;
  }
  .imgContainer {
    width: 100px;
    height: 100px;
  }
  .medicine-1 {
    font-family: "Nunito Sans", sans-serif;
    width: 150px;
    letter-spacing: 0.3px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #414042;
  }
  .medicine-2 {
    // font-family: "Nunito Sans", sans-serif;
    width: 89px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    // margin-left: 40px;
    color: #999999;
    // opacity: 0.7;
  }
  .medicine-3 {
    font-family: "Nunito Sans", sans-serif;
    width: 220px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #414042;
    // opacity: 0.7;
  }
  .medicine-4 {
    font-family: "Nunito Sans", sans-serif;
    width: 130px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #414042;
    opacity: 0.7;
    text-align: right;
  }
  .medicineBar {
    width: 350px;
    height: 0px;
    border: 0.2px solid #ebf1e2;
  }
  .helpContainer {
    display: flex;
    text-align: center;
    width: 450px;
  }
  .helpText {
    margin-top: 20px;
    margin-right: 7px;
    margin-left: 140px;
  }
  .footerWidth {
    width: 350px;
  }
  .doctorText {
    width: 330px;
    height: 29px;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 27px;
    margin-left: 68px;
  }
}

@media screen and (max-width: 480px) {
  .video-box {
    width: 300px;
    height: 180px;
  }

  .media-scroller-3 {
    /* --_spacer: var(--size-5); */
    display: grid;
    /* gap: var(--size-3); */
    grid-auto-flow: column;
    grid-auto-columns: 90%;
    padding: 0 var(--_spacer) var(--_spacer);
    overflow-x: auto;
    overscroll-behavior-inline: contain;
  }
  .ant-input-prefix {
    margin-right: 15px;
    margin-left: 10px;
  }
  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    background-color: #f9f8f8;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    padding-left: 15px;
  }
  .ant-input-affix-wrapper {
    padding: 0px 0px;
  }
  .cta {
    width: 100%;
  }
  .content-box {
    width: 100%;
  }
  .download-btn {
    width: 100%;
  }
  .medicineContainer {
    width: 100%;
  }
  .prescriptionBox {
    width: 100%;
    // margin-left: 535px;
  }
  #prescriptionBody {
    margin-left: 0px;
  }
  .dashboardBar {
    width: 95%;
    // width: 350px;
    height: 0px;
    // margin-top: 10px;
    border: 1.5px solid #b7d340;
    margin-bottom: 25px;
  }
  .topText {
    width: 95%;
    height: 40px;
    font-family: Fredoka;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    text-align: left;
    color: #414042;
  }
  .faq {
    width: 95%;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #bababa;
  }
  .headerText {
    width: 95%;
    // height: 90px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    text-align: left;
    color: #414042;
    // margin-bottom: 5%;
  }
  .footer {
    width: 100%;
    padding: 3.5% 0%;
  }
  .form-box {
    width: 100%;
    margin: 0 auto;
  }
  .subHeaderText {
    width: 95%;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: left;
    color: #414042;
    margin-top: 10px;
  }
  .inputwidth {
    width: 100%;
    height: 54px;
    font-family: "Nunito Sans";
    border-radius: 32px;
    border-color: #b7d340;
    border-width: 2px;
    text-align: left;
    color: #000000;
    font-weight: 600;
  }
  .findOrderIdContainer {
    width: 100%;
  }
  .form-prescription-container {
    width: 95%;
    padding: 10px 0px;
    // background-color: whitesmoke;
    border-radius: 4px;
  }
  .form-find-orderId-container {
    width: 100%;
    padding: 16px 32px;
    // background-color: whitesmoke;
    border-radius: 4px;
  }
  .findOrderIdHeader {
    width: 94%;
  }
  .container {
    position: relative;
    // width: 100%;
    width: 100%;
    height: auto;
    padding: 0% 3%;
    margin: 0 auto;
  }
  .headerBar {
    width: 100%;
    // width: 350px;
    height: 0px;
    margin-top: 10px;
    border: 0.2px solid #ebf1e2;
  }
  .userDetailsContainer {
    // width: 100%;
    width: 350px;
    height: 60px;
    margin-top: 5px;
    padding: 20px;
    // display: flex;
    // justify-content: space-between;
  }
  .userDetail {
    width: 100%;
    height: auto;
    margin-top: -8px;
    margin-left: -15px;
  }
  .userDiagnosisCard {
    // width: 100%;
    width: 350px;
    height: auto;
    margin-top: 10px;
    background-color: #f8f8f8;
    padding: 8% 5%;
  }
  .imgContainer {
    width: 120px;
    height: 120px;
  }
  .medicine-1 {
    font-family: "Nunito Sans", sans-serif;
    width: 50%;
    letter-spacing: 0.3px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #414042;
  }
  .medicine-2 {
    // font-family: "Nunito Sans", sans-serif;
    width: 30%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    // margin-left: 40px;
    color: #999999;
    // opacity: 0.7;
  }
  .medicine-3 {
    font-family: "Nunito Sans", sans-serif;
    width: 60%;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #414042;
    // opacity: 0.7;
  }
  .medicine-4 {
    font-family: "Nunito Sans", sans-serif;
    width: 40%;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #414042;
    opacity: 0.7;
    text-align: right;
  }
  .medicineBar {
    width: 100%;
    // width: 350px;
    height: 0px;
    border: 0.2px solid #ebf1e2;
  }
  .helpContainer {
    display: flex;
    text-align: center;
    // width: 100%;
    width: 350px;
  }
  .helpText {
    margin-top: 5%;
    margin-right: 2%;
    margin-left: 30%;
  }
  .footerWidth {
    width: 100%;
    // width: 350px;
  }
  .doctorText {
    // width: 100%;
    width: 350px;
    height: 29px;
    margin-top: 15px;
    text-align: center;
    margin-left: 0%;
  }
  .downloadBtn {
    left: 38%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  #prescriptionBody {
    margin-left: 440px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #prescriptionBody {
    margin-left: 165px;
  }
}

.loader-container {
  display: flex;
  position: fixed;
  top: 32%;
  right: 0%;
  bottom: 0;
  left: -25%;
  z-index: 50;
  // margin-left: 0.25rem;
  // background-color: #ffffff;
  --bg-opacity: 0.1;
  justify-content: center;
  align-items: center;
  height: 20%;
  // min-height: 100%;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #b7d340;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-drawer-close {
  display: none;
}
// .ant-modal-close {
//   display: none;
// }

.ant-modal-body {
  // padding: 32px 24px;
  padding: 50px 10px 30px 10px;
}
.ant-drawer-header {
  padding: 10px 24px;
}

.ant-drawer-body {
  padding: 10px;
}
.react-player__preview {
  border-radius: 4px;
}

.accordion {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.accordion-1 {
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
}
.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 5px;
  // border-color: #b7d340;
  border-width: thin;
  border-style: solid;
}
.accordion-item-1 {
  background-color: #fff;
  color: #111;
  margin: 0.5rem 0;
  // border-radius: 5px;
  border-bottom: 2px solid #efefef;
  // border-color: #efefef;
  // border-width: thin;
  // border-style: solid;
}
.accordion-item-header {
  padding: 0.5rem 1rem 0.5rem 1rem;
  min-height: 3rem;
  line-height: 0rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.accordion-item-header-1 {
  padding: 0.5rem 1rem 0.5rem 0rem;
  min-height: 3rem;
  line-height: 0rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.accordion-item-icon {
  padding: 0rem 0.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.accordion-item-body {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, #b7d340, transparent) 1;
}

.toggleapp {
  justify-content: end;
  display: flex;
}
.toggle {
  background-color: #f5f5f5;
  mix-blend-mode: normal;
  /* height: 45px;
  width: 300px; */
  height: 40px;
  /* width: 35%; */
  width: 175px;
  border-radius: 4px;
  /* margin-top: 20px; */
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  font-family: "Nunito Sans";
  font-weight: 800;
  // padding-left: 1%;
  // padding-right: 1%;
}
.toggle-left {
  /* height: 40px;
  width: 145px; */
  height: 38px;
  width: 85px;
  background-color: white;
  border-radius: 3px;
  /* margin: 5px; */
  margin-top: 1px;
  margin-left: 1px;
  /* transition: 0.4s; */
  // padding: 2px 0px 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.toggle-right {
  /* height: 40px;
  width: 145px; */
  height: 38px;
  width: 85px;
  background-color: white;
  border-radius: 3px;
  margin-top: 1px;
  margin-right: 1px;
  // margin: 1px 1px 5px 0px;
  /* transition: 0.4s; */
  // padding: 11px 5px 8px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
